import React from "react"
import { Link } from "@StarberryUtils";
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderTwo from "../components/headerTwo/headerTwo"
import Footer from "../components/footer/footer"
import CanHelp from "../components/Home/CanHelp/CanHelp"
//these two imports only for shorturls
import { navigate } from "gatsby"
import axios from "axios"
//import for short url end

// the below async function wait till get data from strapi using graphql
const getPropurl = async (handler, crmid) => {
	let result = []
	//instead of hit REST we use json format to get only required fields. we dont import apollo since this call from another Hook
	var data = JSON.stringify({
		"query": "query($crm_id:[String]) { properties(where: { crm_id: $crm_id, publish: true}) { id, slug, search_type, department } }",
		"variables": {"crm_id": [crmid] }
	  });
	var config = {
		method: 'post',
		url: `${process.env.GATSBY_STRAPI_SRC}/graphql`,
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`
		},
		data : data
	};
	const res = await axios(config);
	result[0] = JSON.stringify(res.data);
	handler(result);//update state var
}

const NotFoundPage = (props) => {
	const [showtemplate, setShowtemplate] = React.useState(false);
	const [propdata, setPropdata] = React.useState('');

	React.useEffect(() => {
		//we doing here as fallback, if any case build failed or not triggered then the below will be useful; when build happens redirects will happen a t netlify itself, so dont need to do CSR
		let regex = "\[a-zA-Z]{3}[0-9]{6}"; //you can modify the preg as per proj feed
		if(props?.location?.pathname){
			let crm_id = (props?.location?.pathname).match(regex);
			//mke sure state value is empty and we have proper url to proceed
			if(typeof crm_id != "undefined" && crm_id != null && crm_id.length > 0 && !propdata){
				getPropurl(setPropdata, crm_id[0]); // call the function to fetch data from strapi
			} else if(propdata){
				let property_data = JSON.parse(propdata);
				let property_arr = property_data.data.properties;
				if(property_arr.length > 0) {
					let property = property_arr[0];
					//set the redirection logic, same mentioned in the gatsby-node for _redirects.txt ie., crm_id to details page
					if(property.department == 'residential') {
						if(property.id) {
						  if (property.search_type == 'sales') {
							navigate(`/property-for-sale/${property.slug}-${property.id}`,{ replace: true });
						  }
						  else if (property.search_type == 'lettings') {
							navigate(`/property-to-rent/${property.slug}-${property.id}`,{ replace: true });
						  }
						}
					}
					if(property.department == 'commercial') {
						if(property.id) {
						  if (property.search_type == 'sales') {
							navigate(`/commercial-property-for-sale/${property.slug}-${property.id}`,{ replace: true });
						  }
						  else if (property.search_type == 'lettings') {
							navigate(`/commercial-property-to-rent/${property.slug}-${property.id}`,{ replace: true });
						  }
						}
					}
					if(property.department == 'new_developments') {
						if(property.id) {
						  if (property.search_type == 'sales') {
							navigate(`/new-homes-for-sale/${property.slug}-${property.id}`,{ replace: true });
						  }
						  else if (property.search_type == 'lettings') {
							navigate(`/new-homes-to-rent/${property.slug}-${property.id}`,{ replace: true });
						  }
						}
					}
				} else {
					setShowtemplate(true);// show 404 page template
				}
			} else {
				setShowtemplate(true);// show 404 page template
			}
		}
	  },[propdata]);

	return (
		<div>
		{showtemplate &&
			<div>
			<HeaderTwo />
			<SEO location={props.location} title="404: Not found" />
			<div className="content">
				<div className="error-page-content">
					<h1><span>Ooops!...</span></h1>
					<h2>The page you’re after seems to be missing.</h2>
					<p>This is maybe because it has been removed, we’ve changed it’s name or it is temporarily unavailable.</p>
					<div class="exp-btns">
						<Link to="/" class="btn">back to homepage</Link>
						<Link to="/contact" class="btn">contact us</Link>
					</div>
				</div>
			</div>
			<CanHelp />
			<Footer Select_Popular_Search="Static_Common" popularSearch="static"/>
			</div>
		}
		</div>
	)
}

export default NotFoundPage
